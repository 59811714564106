.smart {
  .sl-bg-canvas-100 {
    background-color: #f8f3fb;
  }
  .navigation {
    background-color: #501584;

    .logo {
      height: 30px;
    }
  }

  .wrapper {
    button {
      background: #50E3C1;
    }
  }

  h1 {
    font-family: 'ryker-medium';
  }
}
