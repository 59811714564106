// Default styles from element-dev-portal
@import '~@stoplight/elements-dev-portal/styles.min.css';

// Custom themes
@import './themes/smart.scss';
@import './themes/niac.scss';
@import './themes/heat.scss';

.App {
  height: 100vh;
}

.navigation {
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    padding-left: 20px;
  }

  padding-left: calc((100% - 1800px) / 2);
  padding-right: calc((100% - 1950px) / 2);
}

.wrapper {
  display: inline-block;
  width: 75px;
  height: auto;
  margin: 10px 20px 10px 0;
  border: 0 #000;
  padding-right: 20px;

  button {
    appearance: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background: #00e3ff;
    font-family: 'Lato';
    box-shadow: none;
    text-shadow: none;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    transition: linear 0.1s all;

    span {
      color: #003357;
    }

    &:hover {
      background: #00c7f5;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #003357;
      font-weight: bold;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #003357;
      font-weight: bold;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #003357;
      font-weight: bold;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #003357;
      font-weight: bold;
    }
  }
}

.elements-container {
  height: calc(100vh - 58px)
}

.sl-bg-canvas-100 {
  background-color: aliceblue;
  color: black !important;

  .language-json {
    .token.property {
      color: rgb(215, 58, 73) !important;
    }

    .token.string {
      color: rgb(0, 92, 197) !important;
    }

    .token.null {
      color: rgb(215, 58, 73) !important;
    }

    .token.number {
      color: #00873E !important;
    }

    .token.boolean {
      color: rebeccapurple !important;
    }
  }
}

/* POST in sidebar */
.sl-uppercase.sl-text-primary {
  color: #2e859f;
}

.sl-badge.sl-text-paragraph-tiny {
  color: white !important;
}

/* GET badges and successful HTTP response badges */
.sl-bg-success {
  background-color: #7be1c1;
}

/* POST badges */
.sl-bg-primary {
  background-color: #2e859f;
}

/* HTTP warning responses - pre click */
.sl-bg-warning-tint {
  color: purple;
}

/* HTTP warning response - on hover */
.hover\:sl-bg-warning-tint {
  color: #f05151;
}

/* HTTP warning response badges */
.sl-font-medium.sl-bg-warning {
  background-color: #f05151;
}
